import React, { useEffect } from 'react';
import './navbar.scss';
import { Link, useStaticQuery, graphql } from "gatsby"
import * as headerStyles from "./header.module.scss"


const Navbar = () => {
    const [scrolled, setScrolled] = React.useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })
    let navbarClasses = ['navbar'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `
    )

    return (
        <header className={navbarClasses.join(" ")}>
            <div className="logo">
                <Link to="/">{data.site.siteMetadata.title}</Link>
            </div>
            <nav className="navigation">
                <ul className={headerStyles.navList}>
                    <li>
                        <Link to="/" activeClassName={headerStyles.activeMenuItem}>
                            Home
              </Link>
                    </li>
                    <li>
                        <Link to="/blog/" activeClassName={headerStyles.activeMenuItem}>
                            Blog
              </Link>
                    </li>
                    <li>
                        <Link
                            to="/contact/"
                            activeClassName={headerStyles.activeMenuItem}
                        >
                            Contact
              </Link>
                    </li>
                    <li>
                        <Link to="/about/" activeClassName={headerStyles.activeMenuItem}>
                            About
              </Link>
                    </li>
                </ul>
            </nav>

        </header>
    )
};
export default Navbar;