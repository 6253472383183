import React from "react"
import * as footerStyles from "./footer.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagramSquare, faTwitter, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )
  return (
    <footer className={footerStyles.siteFooter}>
      <div className={footerStyles.container}>
        <p>
          Site developed by {data.site.siteMetadata.author} &copy;{" "}
          {new Date().getFullYear().toString()}{" "}&bull;{" "}{" Find us on: "}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/coaster_experts"
          >
            <FontAwesomeIcon icon={faInstagramSquare} size="lg" />
          </a>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://facebook.com/coasterexperts"
          >
            <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
          </a>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/CoasterExperts"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCDqsLhek75kGSnLFE9Ftsgg"
          >
            <FontAwesomeIcon icon={faYoutubeSquare} size="lg" />
          </a>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:ariel@coasterexperts.com"
          >
            <FontAwesomeIcon icon={faMailBulk} size="lg" />
          </a>{" "}
        </p>
      </div>
    </footer>
  )
}

export default Footer
